import React from "react";
import { useTranslation } from "react-i18next";

import { Card, Col, Badge } from "react-bootstrap";

import { DateSpanComponent } from "../Components/DateSpanComponent";

import { Link } from "react-router-dom";

export interface Project {
  name: string;
  url: string;
  from: string;
  to: string;
  descriptions: string[];
  technologies: string[];
  roles: string[];
}

interface ProjectsProps {
  listAll: boolean;
}

export const ProjectList: React.FC<ProjectsProps> = ({ listAll }) => {
  const { t } = useTranslation();

  let projects: Project[] = [
    /*{
      name: "ChronoPlan (in development)",
      url: "https://github.com/jayq97/",
      from: "2024-07-01",
      to: t("datespan.now"),
      descriptions: [
        "ChronoPlan is an app that allows the user to enter appointments in the calendar and also set up to-dos and habits.",
      ],
      technologies: [
        "TypeScript",
        "React",
        "GraphQL",
        "C#",
        "ASP.NET",
        "Entity Framework",
        "PostgreSQL",
      ],
      roles: ["Frontend Design", "API Creation", "Backend Architecture"],
    },*/
    {
      name: "Parcel Track'n'Trace",
      url: "https://github.com/jayq97/Parcel-Track-n-Trace",
      from: "2022-09-01",
      to: "2023-01-31",
      descriptions: t("project-list.parceltnt.descriptions", {
        returnObjects: true,
      }) as string[],
      technologies: [
        "C#",
        "ASP.NET",
        "Entity Framework",
        "AutoMapper",
        "MS SQL",
        "NUnit",
        "Moq",
      ],
      roles: t("project-list.parceltnt.roles", {
        returnObjects: true,
      }) as string[],
    },
    {
      name: "Urban Heat Island",
      url: "https://github.com/jayq97/UrbanHeatIslands-Frontend",
      from: "2021-09-01",
      to: "2023-01-31",
      descriptions: t("project-list.uhi.descriptions", {
        returnObjects: true,
      }) as string[],
      technologies: [
        "JavaScript",
        "React",
        "Leaflet.js",
        "D3.js",
        "OpenWeatherMap API",
        "Node.js",
        "Express.js",
        "PostgreSQL",
      ],
      roles: t("project-list.uhi.roles", {
        returnObjects: true,
      }) as string[],
    },
    {
      name: "TourPlanner",
      url: "https://github.com/jayq97/TourPlanner",
      from: "2022-02-01",
      to: "2022-06-30",
      descriptions: t("project-list.tourplanner.descriptions", {
        returnObjects: true,
      }) as string[],
      technologies: ["C#", "WPF", "PostgreSQL", "MapQuest API", "NUnit", "Moq"],
      roles: t("project-list.tourplanner.roles", {
        returnObjects: true,
      }) as string[],
    },
    {
      name: "Monster Trading Card Game",
      url: "https://github.com/jayq97/MonsterTradingCardGame",
      from: "2021-09-01",
      to: "2022-01-31",
      descriptions: t("project-list.mtcg.descriptions", {
        returnObjects: true,
      }) as string[],
      technologies: ["C#", "PostgreSQL", "NUnit", "Moq"],
      roles: t("project-list.mtcg.roles", {
        returnObjects: true,
      }) as string[],
    },
    {
      name: "Gamehub",
      url: "https://github.com/jayq97/Gamehub",
      from: "2021-02-01",
      to: "2021-06-30",
      descriptions: t("project-list.gamehub.descriptions", {
        returnObjects: true,
      }) as string[],
      technologies: ["JavaScript", "Socket.IO", "PHP", "MySQL"],
      roles: t("project-list.gamehub.roles", {
        returnObjects: true,
      }) as string[],
    },
  ];

  return listAll ? (
    <>
      {projects.map((project, index) => (
        <Col className="d-flex" key={index} lg={6} md={12}>
          <Card as={Link} to={project.url} className="project-box">
            <h2 className="project-title">{project.name}</h2>
            <DateSpanComponent
              start={project.from}
              end={project.to}
              showSpan={true}
            />

            <ul>
              {project.descriptions.map((description, index2) => (
                <li key={index2}>{description}</li>
              ))}
            </ul>
            <p>{t("project-list.my-roles")}:</p>
            <div className="project-tag-container">
              {project.roles.map((role, index) => (
                <Badge
                  pill
                  key={index}
                  bg="light"
                  text="dark"
                  className="mr-2 mb-2"
                >
                  {role}
                </Badge>
              ))}
            </div>
            <br />
            <p>{t("project-list.used-technologies")}:</p>
            <div className="project-tag-container">
              {project.technologies.map((technology, index) => (
                <Badge
                  pill
                  key={index}
                  bg="warning"
                  text="dark"
                  className="mr-2 mb-2"
                >
                  {technology}
                </Badge>
              ))}
            </div>
          </Card>
        </Col>
      ))}
    </>
  ) : (
    <>
      {projects.slice(0, 2).map((project, index) => (
        <Col className="d-flex" key={index} lg={6} md={12}>
          <Card as={Link} to={project.url} className="project-box">
            <h2 className="project-title">{project.name}</h2>
            <DateSpanComponent
              start={project.from}
              end={project.to}
              showSpan={false}
            />
            <p>{project.descriptions[0]}</p>

            <div className="project-tag-container">
              {project.technologies.map((technology, index) => (
                <Badge
                  key={index}
                  bg="warning"
                  style={{ color: "black" }}
                  className="mr-2 mb-2"
                >
                  {technology}
                </Badge>
              ))}
            </div>
          </Card>
        </Col>
      ))}
    </>
  );
};
