import { Col, Container, Row } from "react-bootstrap";
import { FiExternalLink } from "react-icons/fi";

import { useTranslation } from "react-i18next";

import { WorkList } from "../Data/WorkList";
import { EducationList } from "../Data/EducationList";
import { ProjectList } from "../Data/ProjectList";

export const Home = () => {
  const { t } = useTranslation();

  return (
    <Container className="home-main-container">
      <Col className="home-title d-flex flex-column align-items-center justify-content-center text-center">
        <h1>
          {t("home.greeting")} <strong>Jay</strong>.
        </h1>
        <p>
          {t("home.introduction.0")} <strong>{t("home.introduction.1")}</strong>{" "}
          {t("home.introduction.2")} <strong>{t("home.introduction.3")}</strong>
          {t("home.introduction.4")}
          {t("home.introduction.5")}
        </p>
      </Col>
      <Row className="home-item">
        <Col lg={6} md={12}>
          <h3 className="home-experience-title">
            {t("home.work")}{" "}
            <a href="/#/experience">
              <FiExternalLink style={{ marginBottom: "10px" }} />
            </a>
          </h3>
          <WorkList listAll={false} />
        </Col>
        <Col lg={6} md={12}>
          <h3 className="home-experience-title">
            {t("home.education")}{" "}
            <a href="/#/experience">
              <FiExternalLink style={{ marginBottom: "10px" }} />
            </a>
          </h3>
          <EducationList listAll={false} />
        </Col>
      </Row>
      <Row className="home-item">
        <h3 className="home-projects-title">
          {t("navbar.projects")}{" "}
          <a href="/#/projects">
            <FiExternalLink style={{ marginBottom: "10px" }} />
          </a>
        </h3>
        <ProjectList listAll={false} />
      </Row>
    </Container>
  );
};
