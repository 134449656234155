import React from "react";
import { format, parseISO, differenceInMonths, Locale } from "date-fns";
import { enUS, deAT } from "date-fns/locale";

import { useTranslation } from "react-i18next";

import { TFunction } from "i18next";

const locales = { en: enUS, de: deAT };

interface DateSpanComponentProps {
  start: string;
  end: string;
  showSpan: boolean;
}

const formatDate = (date: Date, locale: Locale) =>
  format(date, "MMM yyyy", { locale });

const getDateDifference = (start: string, end: string, t: TFunction) => {
  const startDate = parseISO(start);
  const endDate = end === t("datespan.now") ? new Date() : parseISO(end);
  const totalMonths = differenceInMonths(endDate, startDate) + 1;

  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  let formattedDifference = "";

  if (years > 0) {
    formattedDifference += t(years === 1 ? "datespan.year" : "datespan.years", {
      count: years,
    });
  }
  if (months > 0) {
    if (years > 0) {
      formattedDifference += ", ";
    }
    formattedDifference += t(
      months === 1 ? "datespan.month" : "datespan.months",
      { count: months }
    );
  }

  return { startDate, endDate, formattedDifference };
};

export const DateSpanComponent: React.FC<DateSpanComponentProps> = ({
  start,
  end,
  showSpan,
}) => {
  const { i18n, t } = useTranslation();
  const language = i18n.language as "en" | "de";

  const locale = locales[language];

  const { startDate, endDate, formattedDifference } = getDateDifference(
    start,
    end,
    t
  );

  const formattedStartDate = formatDate(startDate, locale);
  const formattedEndDate =
    end === t("datespan.now") ? t("datespan.now") : formatDate(endDate, locale);

  return showSpan ? (
    <p>{`${formattedStartDate} - ${formattedEndDate} (${formattedDifference})`}</p>
  ) : (
    <p>{`${formattedStartDate} - ${formattedEndDate}`}</p>
  );
};

export default DateSpanComponent;
